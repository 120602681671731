import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/organisms/header"
import Footer from "../components/organisms/footer"
import Hero from "../components/organisms/hero"
import Clients from "../components/molecules/clients"
import OurWork from "../components/molecules/our-work"
import Services from "../components/organisms/services"
import Sentences from "../components/organisms/sentences"
import Contacts from "../components/organisms/contacts"
import { clients } from "../utils/clients"
import { sentences } from "../utils/sentences"

const services = [
  {
    icon: "translate",
    title: "Tradução",
    text:
      "Traduzimos os seus textos no seu significado e intenção, mantendo o estilo e ritmo com os quais deseja comunicar.",
  },
  {
    icon: "revision",
    title: "Revisão",
    text:
      "A revisão de textos pode passar por simples correções que melhoram a leitura e compreensão dos mesmos, ou até mesmo por sugerir alterações que tornem o texto mais claro e objectivo.",
  },
  {
    icon: "creation",
    title: "Criação de Conteúdos",
    text:
      "Tem uma ideia do que deseja comunicar mas faltam-lhe as palavras? Nós temos muitas para partilhar. Ajudamos a criar o que pensou para que o resultado seja ainda melhor do que imaginou.",
  },
]

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Inglês para marketing" />
      <Header
        siteTitle={data.site.siteMetadata.title}
        i18n={<a href="/en">EN</a>}
        linkedin="https://www.linkedin.com/company/inglesparamarketing"
        facebook="https://facebook.com/marketINGinglesparamarketing"
      />
      <Hero
        title="Inglês para marketing"
        subtitle="Tradução, revisão e criação de conteúdos PT/ING - ING/PT"
        buttonUrl="#our-work"
        button="Saber Mais"
      />
      <Clients clients={clients} />
      <OurWork
        id="our-work"
        title="O que trabalhamos?"
        text="Materiais da área do marketing para empresas e profissionais que
        comuniquem em inglês ou em inglês e português. Sites, newsletters,
        comunicação corporativa, e-mails, publicações nas redes sociais,
        artigos, press releases, anúncios, entre outros."
      />
      <Services services={services} />
      <Sentences slides={sentences} />
      <Contacts
        title="Contactos"
        description="Somos bilíngues Inglês e Português. Compreendemos ambas as línguas nas suas nuances, nos seus truques e na sua beleza. Se procura um toque nativo para a sua comunicação, entre em contacto."
        email="ola@inglesparamarketing.com"
      />
      <Footer
        linkedin="https://www.linkedin.com/company/inglesparamarketing"
        facebook="https://facebook.com/marketINGinglesparamarketing"
        copyright={`Copyright © ${new Date().getFullYear()} Todos os direitos reservados.`}
      />
    </Layout>
  )
}

export default IndexPage
